<template>
    <el-dialog width="600" :title="$t('TappayAccountSetting')" :visible="dialogAccountVisible" @close="dialogOnClose()">
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small">
            <!--Added by Lillian (20240425): 若為系統管理者, 可為其他營運商新增資料, 不過要先確認此為合法且已新增之營運商, 所以只能用下拉選單來挑選. -->
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22" style="text-align: center;">
                    <!--利用this.isModify來判斷是要新增或是修改資料-->
                    <el-form-item prop="serviceId" v-bind:label="$t('Operator')" v-if="serviceList.length>0 && !this.isModify">
                        <el-select id="serviceId" v-model="updateQuery.serviceId" :disabled="this.isModify" size="small" class="filter-item custom-percent" >
                            <el-option
                                 v-for="item in this.serviceList"
                                 :label="item.name + ' (' + item.code + ')'"
                                 :key="item.id"
                                 :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End -- Added by Lillian (20240425) -->
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                    <!--利用this.isModify來判斷是要新增或是修改資料, 只有新增資料才會顯示Partner_Key欄位供輸入.-->
                    <el-form-item prop="partnerKey" :label="$t('TappayPartnerKey')" v-if="!this.isModify">
                        <el-input id="partnerKey" size="small" v-model="updateQuery.partnerKey" :maxlength="64"
                            :placeholder="$t('EnterTappayPartnerKey')" class="filter-item custom-percent" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                    <el-form-item prop="merchantId" :label="$t('TappayMerchantId')" >
                        <el-input id="merchantId" size="small" v-model="updateQuery.merchantId" :maxlength="50"
                        :placeholder="$t('EnterTappayMerchantId')" class="filter-item" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- Removed by Lillian (20240906)
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                    <el-form-item prop="merchantIDofLinePay" :label="$t('TappayMerchantIDofLinePay')">
                        <el-input id="merchantIDofLinePay" size="small" v-model="updateQuery.merchantIDofLinePay"
                        :placeholder="$t('EnterTappayMerchantIDofLinePay')" class="filter-item custom-percent" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            -->
            <!-- End -- Added by Lillian (20240507) -->
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                    <el-form-item prop="partnerAccount" :label="$t('PartnerAccount')" >
                        <el-input id="partnerAccount" size="small" v-model="updateQuery.partnerAccount" :maxlength="50"
                        :placeholder="$t('EnterPartnerAccount')" class="filter-item" clearable />
                    </el-form-item>
                </el-col>
            </el-row>

            <!--Added by Lillian (20240412)-->
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22" >
                    <el-form-item prop="isSetServerIP" :label="$t('IsSetServerIP')" >
                        <el-checkbox id="isSetServerIP" v-model="updateQuery.isSetServerIP">
                        </el-checkbox>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22" >
                    <el-form-item prop="isAppRegistered" :label="$t('IsAppRegistered')">
                        <el-checkbox id="isAppRegistered" v-model="updateQuery.isAppRegistered">
                        </el-checkbox>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
              <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                <el-col>
                  <!--
                  <div style="margin-top: 0px; margin-left: 20px; font-size: 14px; text-align: left;">{{$t('InvoiceServiceNote')}}</div>
                  -->
                  <div style="margin-top: 10px; margin-left: 20px; font-size: 14px; text-align: left;">{{$t('PartnerKeyNote')}}</div>
                  <div style="margin-top: 3px; margin-left: 20px; font-size: 14px; text-align: left;">{{$t('MerchantIdNote')}}</div>
                  <div style="margin-top: 3px; margin-left: 20px; font-size: 14px; text-align: left;">{{$t('IPNote')}}</div>
                  <div style="margin-top: 3px; margin-left: 20px; font-size: 14px; text-align: left;">{{$t('AppNote')}}</div>
                </el-col>
              </el-col>
            </el-row>
            <!--End_Added by Lillian (20240412)-->
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary"
            @click="updateTappay()">{{$t('Save')}}</el-button>

        <br/><br/>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
export default {
    name: 'TappaySetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        tappayData: {
            require: false,
            default: undefined,
            type: Object
        },
        dialogAccountVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return{
            dialogWidth: 0,
            total: 0,
            list: [],
            listLoading: false,
            serviceList: [],
            isModify: false,
            updateQuery: {
                id: undefined,
                serviceId: undefined, //Added by Lillian (20240425)
                partnerKey: undefined,
                merchantId: undefined,
                partnerAccount: undefined, //Added by Lillian (20241022)
                isSetServerIP: undefined,
                isAppRegistered: undefined,
            },
            rules: {
                serviceId: [{ required: true, message: '(必填)', trigger: 'blur' }], //Added by Lillian (20240425)
                partnerKey: [{ required: true, message: '(必填)', trigger: 'blur' }],
                merchantId: [{ required: true, message: '(必填)', trigger: 'blur' }],
                partnerAccount: [{ required: true, message: '(必填)', trigger: 'blur' }], //Added by Lillian (20241022)
                isSetServerIP: [{ required: false, message: '', trigger: 'blur' }],
                isAppRegistered: [{ required: false, message: '', trigger: 'blur' }],
            },
        }
    },
    watch: {
        tappayData(val) {
            if (val) {
                this.isModify = true
                console.log("isModify:" + this.isModify);
                this.updateQuery.id = val.id
                this.updateQuery.serviceId = val.serviceId //Added by Lillian (20240425)
                this.updateQuery.partnerKey = val.partnerKey
                this.updateQuery.merchantId = val.merchantId
                this.updateQuery.partnerAccount = val.partnerAccount //Added by Lillian (20241022)
                this.updateQuery.isSetServerIP = val.isSetServerIP
                this.updateQuery.isAppRegistered = val.isAppRegistered
            }
        },
    },
    async created() {
        this.getServiceOptions(); //Added by Lillian (20240425)
    },
    methods: {
        dialogOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        updateTappay() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.axios.put('/api/v1/tappay', this.updateQuery).then(() => {
                        this.$message({
                            showClose: true,
                            message: this.$t('SuccessEdited'),
                            type: 'success'
                        })
                        this.dialogOnClose()
                    }).catch(err => {
                        console.log('err:' + err)
                        if(err.data.includes('ExistKey')) {
                          this.$message({
                             message: this.$t('ExistKey'),
                             type: 'error'
                          })
                        } else if(err.data.includes('SameTappaySetting')) {
                          this.$message({
                             message: this.$t('SameTappaySetting'),
                             type: 'error'
                          })
                        } else if(err.data.includes('NotAdmin')) {
                          this.$message({
                             message: this.$t('NotAdmin'),
                             type: 'error'
                          })
                        } else if(err.data.includes('ExistService')) {
                          this.$message({
                             message: this.$t('ExistService'),
                             type: 'error'
                          })
                        } else {
                          this.$message({
                             message: this.$t('FailEdited'),
                             type: 'error'
                          })
                        }
                    })
                } else {
                    this.$message({
                        message: this.$t('IncorrectInput'),
                        type: 'error'
                    })
                }
            })
        },
        resetUpdateQuery() {
            this.isModify = false
            this.updateQuery = {
                id: undefined,
                serviceId: undefined, //Added by Lillian (20240425)
                partnerKey: undefined,
                merchantId: undefined,
                partnerAccount: undefined, //Added by Lillian (20241022)
                isSetServerIP: undefined,
                isAppRegistered: undefined,
            }

            //Added by Lillian (20240516) -- 清空設定以免新增資訊時有殘值.
            this.updateQuery.isSetServerIP = false
            this.updateQuery.isAppRegistered = false
        },
        getServiceOptions() { //Added by Lillian (20240425)
            this.listLoading = true
            //提供給管理系統要取得營運商列表使用@CommonController, 若登入角色為系統管理者, 會自動取得該列表.
            this.axios.get('/api/v1/common/service/enabled').then(res => {
              this.serviceList = res.data
            })
        },
    }
}
</script>
<style>
.el-form-item__error {
    top: 80%
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) { 
    .el-form-item__content {
        text-align: left;
    }
    .el-form-item__label {
        font-size: .92em;
        padding: 0px 5px 0px 0px;
    }
    
}

@media (min-width: 768px) { 
    
    .custom-percent {
        width: 90%;
    }
    
    .el-form-item__content {
        text-align: left;
        margin-left: 180px;
    }

    .el-form-item__label {
        width: 180px;
    }
 }
</style>