<template>
  <div>
    <el-row>
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" style="text-align: left;">
        <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleTappayAdd">
          {{$t('TappayAddAccount')}}
        </el-button>
      </el-col>
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" style="text-align: right;">
        <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')"
          class="filter-item-mid" v-if="serviceList.length > 0">
          <el-option key="0" :label="$t('AllOperator')" value="0"></el-option>
          <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'"
            :value="item.id" />
        </el-select>
      </el-col>
    </el-row>
    <el-table :header-cell-style="{ color: '#20638C' }" v-loading="listLoading" :data="list" style="width: 100%;"
      :cell-style="{ padding: '0', height: '40px' }" @sort-change="tableSortChange">
      <!--<el-table-column align="center" prop="id" label="編號" sortable />-->
      <!--Modified by Lillian (20240425): 非系統管理員登入後, 不用顯示營運商名稱.
      <el-table-column align="center" prop="serviceInfo.name" :label="$t('Operator')" />
      -->
      <el-table-column v-if="serviceList.length > 0" align="center" prop="serviceInfo.name" :label="$t('Operator')" />
      <!--
      <el-table-column align="center" prop="partnerKey" :label="$t('TappayPartnerKey')" />
      -->
      <el-table-column align="center" prop="merchantId" :label="$t('TappayMerchantId')" />
      <!-- Added by Lillian (20241022) -->
      <el-table-column align="center" prop="partnerAccount" :label="$t('PartnerAccount')" />
      <!--
      <el-table-column align="center" prop="serviceInfo.name" :label="$t('Operator')" />
      -->
      <!-- Added by Lillian (20240411)-->
      <el-table-column align="center" prop="isSetServerIP" :label="$t('IsSetServerIP')">
        <template slot-scope="scope">
           <el-checkbox v-model="scope.row.isSetServerIP"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="isAppRegistered" :label="$t('IsAppRegistered')">
        <template slot-scope="scope">
           <el-checkbox v-model="scope.row.isAppRegistered"></el-checkbox>
        </template>
      </el-table-column>
      <!-- End_Added by Lillian (20240411)-->
      <el-table-column align="center" prop="updateTime" v-bind:label="$t('Update') + $t('Date')" sortable
        :show-overflow-tooltip="true" min-width="60%">
        <template slot-scope="scope">
          {{ parseDateTime(scope.row.updateTime) }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createTime" v-bind:label="$t('Create') + $t('Date')" sortable
        :show-overflow-tooltip="true" min-width="60%">
        <template slot-scope="scope">
          {{ parseDateTime(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('Operate')" width="130px" >
        <template slot-scope="scope">
          <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showSetting(scope.row)" />
          <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" @click="handleDelete(scope.$index, scope.row)" />
          <!-- Removed by Lillian (20240425): 系統管理員可以協助營運商修改設定
          <el-button v-if="scope.row.serviceInfo.id == $store.state.currentAccount.serviceInfo.id" type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showSetting(scope.row)" />
          <el-button v-if="scope.row.serviceInfo.id == $store.state.currentAccount.serviceInfo.id" type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" @click="handleDelete(scope.$index, scope.row)" />
          -->
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-col>
          <!--
          <div style="margin-top: 20px; margin-left: 20px; font-size: 14px; text-align: left;">{{$t('InvoiceServiceNote')}}</div>
          -->
          <div style="margin-top: 10px; margin-left: 20px; font-size: 14px; text-align: left;">{{$t('PartnerKeyNote')}}</div>
          <div style="margin-top: 3px; margin-left: 20px; font-size: 14px; text-align: left;">{{$t('MerchantIdNote')}}</div>
          <div style="margin-top: 3px; margin-left: 20px; font-size: 14px; text-align: left;">{{$t('IPNote')}}</div>
          <div style="margin-top: 3px; margin-left: 20px; font-size: 14px; text-align: left;">{{$t('AppNote')}}</div>
        </el-col>
      </el-col>
    </el-row>
    <!--分頁組件-->
    <pagination v-show="total > 0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size"
      @pagination="getList" />
    <TappaySetting :dialogAccountVisible="dialogSettingVisible" :tappayData="tappayData" 
      @close="dialogTappaySettingOnClose()" />
    <!--隱藏按鈕測試Tappay-->
    <!--
    <div class="container">
       <el-button class="show-hide-button" type="text" @click="showButton = !showButton">test</el-button>
       <el-button v-show="showButton" class="button" @click="handleButtonClick"></el-button>
    </div>
    -->
    <!--End_隱藏按鈕測試Tappay-->
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import TappaySetting from './TappaySetting.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
export default {
  name: 'Tappay',
  components: {
    Pagination,
    TappaySetting,
  },
  mixins: [mixins],
  data() {
    return {
      result: '',
      dialogWidth: 0,
      total: 0,
      list: [],
      listLoading: false,
      listQuery: {
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15,
        serviceId: "0",
        sort: 'id,Desc'
      },
      serviceList: [],
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      tappayData: undefined, //@TappaySetting.vue
      dialogSettingVisible: false,
      showButton: false //Try by Lillian (20240515)
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function (newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.serviceId': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    }
  },
  async created() {
    this.dialogWidth = this.setDialogWidth()
    this.getServiceOptions();
    this.resetPage(this.getList)
    //await refreshToken();
  },
  beforeDestroy(){
    //stopRefreshToken();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/tappay', { params: this.listQuery }).then(res => {
        this.list = res.data.content
        this.total = res.data.totalElements
        this.listLoading = false
      })
    },
    getServiceOptions() {
      this.listLoading = true
      //提供給管理系統要取得營運商列表使用@CommonController, 若登入角色為系統管理者, 會自動取得該列表.
      this.axios.get('/api/v1/common/service/enabled').then(res => {
        this.serviceList = res.data
      })
    },
    handleTappayAdd() {
      //Modified by Lillian (20240906): 可能統一由CHT向Tappay申請partner_key, 使多家營運商共用該partner_key.
      let hadCreated = false;
      this.list.forEach((tappay) =>{
        console.log(tappay.serviceInfo.id)
      });
      if(!hadCreated) {
        this.dialogSettingVisible = true
      } else { //編輯過程中
        this.$message({
          showClose: true,
          message:  this.$t('ExistService'),
          type: 'error'
        });
      }
    },
    handleDelete(index, row) {
      this.$confirm(this.$t('Hint'), {
        message: this.$t('ConfirmDeleteTappay')+ '-' + row.merchantId + '?',
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.delTappay(index, row.id)
      }).catch(() => {
        console.log('cancel delete account')
      })
    },
    async delTappay(index, id) {
      this.axios.delete('/api/v1/tappay/' + id).then(() => {
        this.$delete(this.list, index);
        this.$message({
          showClose: true,
          message: this.$t('Delete') + this.$t('Success'),
          type: 'success'
        })
      }).catch(err => {
        console.log('err:' + err)
        this.$message({
          message: this.$t('Delete') + this.$t('Fail'),
          type: 'error'
        })
      })
    },
    showSetting(row) {
      this.dialogSettingVisible = true
      this.tappayData = row
    },
    dialogTappaySettingOnClose() {
      this.dialogSettingVisible = false
      this.tappayData = undefined
      this.resetPage(this.getList)
    },
  }
}
</script>
<style lang="scss" scoped>
// 設定 hover css效果
.el-table .cell:hover {
  .title {
    color: #004B97;
    font-weight: bold;
    cursor: pointer;
  }
}

//Try by Lillian (20240515)
 .container {
   background-color: white;
   padding: 20px;
   position: relative;
   display: inline-block;
 }
 .button {
   display: none;
   position: absolute;
   bottom: 10px;
   right: 10px;
 }
 .container:hover .button {
   display: inline-block;
 }
 .show-hide-button {
   background-color: white;
   color: white;
 }
 //End_Try by Lillian (20240515)
</style>
